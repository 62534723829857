import React from 'react'

const Home = () => {
  return (
    <div>
      <h2 class=' text-left text-xl font-bold py-9 px-7'>Home Page</h2>
    </div>
  )
}

export default Home
